/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-31 16:38:50
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-03 16:02:04
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const getMineReceivingNote = params => axios({
    url: '/api/order/system/orderShipInfo/getMineReceivingNote',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


export const selectByIdOrderShipInfo = params => axios({
    url: '/api/order/system/orderShipInfo/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
